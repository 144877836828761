<template>
  <MaterialStatsCard
    color="warning"
    icon="mdi-alert"
    :title="$t('dashboard.incomplete-mapped.title', 2)"
    :value="totalUnmapped"
    sub-icon="mdi-eye"
    :sub-text="$t('dashboard.incomplete-mapped.show_all')"
    :sub-link="showAttributes"
  >
    <template v-slot:table>
      <v-simple-table table-borderless dense theme--light>
        <tbody>
          <tr v-for="item in attributes" :key="item.id">
            <td>{{ item.name }}</td>
            <!--            <td>{{ item.totalInputValues }}</td>-->
            <td>{{ item.totalUnmappedInputValues }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </MaterialStatsCard>
</template>

<script>
import MaterialStatsCard from "@/components/dashboard/MaterialStatsCard";
import AttributeService from "@/services/AttributeService";

export default {
  name: "UnmappedAttributes",
  components: { MaterialStatsCard },
  data: () => ({
    attributes: [],
    totals: [],
    loading: false
  }),
  methods: {
    showAttributes() {
      this.$router.push({
        name: "attributes"
      });
    }
  },
  computed: {
    totalUnmapped() {
      return this.totals["totalUnmappedInputValues"];
    }
  },
  mounted() {
    this.loading = true;
    AttributeService.fetchAttributes(1, 8, "totalUnmappedInputValues", true, [])
      .then(json => {
        this.attributes = json.items;
      })
      .catch(err => {
        console.log(err);
      });
    AttributeService.fetchAttributesTotalCounts()
      .then(json => {
        this.totals = json;
      })
      .catch(err => {
        console.log(err);
      });
    this.loading = false;
  }
};
</script>

<style scoped>
.v-data-table table td,
.v-data-table table th {
  border: 0 !important;
}

tbody tr {
  background-color: rgba(0, 0, 0, 0);
}

tbody tr:hover {
  background-color: transparent !important;
}

tbody td {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8125rem !important;
  font-weight: 500;
  line-height: 1rem !important;
}
</style>
