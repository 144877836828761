<template>
  <v-container fluid>
    <Header class="mb-2">
      <template v-slot:title>
        {{ $t("dashboard.title") }}
      </template>
    </Header>
    <v-row>
      <v-col cols="12" sm="6" lg="3">
        <UnmappedAttributes />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UnmappedAttributes from "@/components/dashboard/UnmappedAttributes";

export default {
  name: "Dashboard",
  components: { UnmappedAttributes }
};
</script>
